<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
    page: {
        title: "Penggunaan Tempat Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
        Pagination,
    },
    data() {
    

        return {
            title: "Izin Penggunaan Tempat Uji Kompetensi",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Izin Penggunaan Tempat Uji Kompetensi",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            currentTablePage: "",
            optionsSkemaSertifikasi : [],
            selectedSkemaSertifikasi : "",
            optionsAsesor : [],
            selectedAsesor : "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    mounted() {
        this.getDataTable();
        this.getSkemaSertifikasi();
        this.getAsesor();
    },
    methods: {
        ubahStatus(id) {
            console.log(id);
            var alert_text = "Apakah Anda yakin untuk mengubah status?";
            Swal.fire({
                title: "Peringatan !",
                text: alert_text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                } else {
                    Swal.close();
                }
            });
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        console.log(response_data_fix);
                        self.table_data = response_data_fix.list_data.data;
                        self.currentTablePage = response_data_fix.list_data.current_page;
                        console.log(self.table_data);
                        self.pagination = response_data_fix.pagination;

                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        // ref-skema-sertifikasi
        getSkemaSertifikasi(){
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsSkemaSertifikasi = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        getAsesor(){
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsAsesor = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },

        
        ubahStatusVertifikasi(id_penggunaan_tuk){
            let self = this;
            self.$router.push({ name: "add-surat_tugas_vertifikasi" , params: { id_penggunaan: id_penggunaan_tuk } })
        }

    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Izin Penggunaan Tempat Uji Kompetensi</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    &nbsp;
                                    <button class="btn btn-sm btn-success m-1"><i class="fas fa-file-excel"></i> Cetak
                                        Excel</button>
                                    <button class="btn btn-sm btn-danger m-1"><i class="fas fa-file-pdf"></i> Cetak
                                        PDF</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih Skema Sertifikasi</label>
                                        <select class="form-select" id="statusTable" v-model="selectedSkemaSertifikasi">
                                            <option value="">Pilih Skema Sertifikasi</option>
                                            <option v-for="(row_data, key_data) in optionsSkemaSertifikasi" :key="key_data" :value="row_data.id_skema_sertifikasi">
                                                {{ row_data.nama_skema_sertifikasi }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih Asesor</label>
                                        <select class="form-select" id="statusTable" v-model="selectedAsesor">
                                            <option value="">Pilih Asesor</option>
                                            <option v-for="(row_data, key_data) in optionsAsesor" :key="key_data" :value="row_data.user_id">
                                                {{ row_data.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2 mt-4">
                                        <button class="btn btn-info btn-sm" style="margin-top:10px !important"><i
                                                class="fas fa-search"></i> Cari Data</button>
                                    </div>
                                    <div class="form-group col-md-6 mt-4 text-end">
                                        <router-link :to="{ name: 'add-penggunaan_tuk' }" class="btn btn-sm btn-primary m-1" style="margin-top:10px !important" 
                                            v-if="sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1' || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'">
                                            <i class="fa fa-plus"></i> Tambah Tempat Uji Kompetensi
                                        </router-link>    
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable"
                                                        style="width: 100%" v-model="tableData.length"
                                                        @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable"
                                            placeholder="Cari Data ..." v-model="tableData.search"
                                            @input="getDataTable()" />
                                    </div>
                                </div>
                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                    <thead class="bg-dark text-center text-white">
                                        <tr>
                                            <th>No</th>
                                            <th>No. Jadwal Penggunaan</th>
                                            <!-- <th>Asesor Verifikator</th> -->
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-if="loadingTable">
                                            <td colspan="11" class="text-center">
                                                <i class="fas fa-spinner fa-spin"></i> Loading...
                                            </td>
                                        </tr>
                                        <tr v-else-if="table_data.length == 0">
                                            <td class="text-center" colspan="11">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ ((parseInt(tableData.length) * parseInt(currentTablePage)) - parseInt(tableData.length)) + key_data + 1}}.
                                            </td>
                                            <td>{{ row_data?.penggunaan_nomor}}</td>
                                            <td class="text-center"> {{ row_data?.status_nama }}</td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link
                                                        :to="{ name: 'detail-penggunaan_tuk', params: { id: row_data.id } }"
                                                        class="btn btn-sm btn-primary bg-primary"><i
                                                            class="fas fa-search"></i> Detail Penggunaan
                                                    </router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
